<template>
  <!-- 客户案例 -->
  <div class="">
    <head-nav></head-nav>
    <!--  头部导航   -->
    <div>
      <header class="index1">
        <div class="backgroundImg">
          <!-- <head-nav></head-nav> -->
          <!-- 背景图 -->
          <div class="backgroundImgAll" style="">
            <div class="backgroundImg1">客户案例</div>
            <div class="backgroundImg2">CUSTOMER CASE</div>
          </div>
        </div>
      </header>
    </div>

    <!--  -->
    <div class="case">
      <div class="case-1">
        <div class="case1">300000+企业的信赖选择</div>
        <div class="case2">
          覆盖互联网、金融、电商、汽车、在线教育、企业服务、医疗等众多领域
        </div>
        <!-- <div class="caseChange">
          <div
            v-for="(item, index) in caseList"
            :key="index"
            class="caseChange1"
            @click="changeCase(item.url)"
          >
            <div></div>
            <div></div>
            <div></div>
            <div class="caseChange1-1"><span style="cursor: pointer;">了解案例</span></div>
          </div> -->
          <!-- <div class="caseChange1">
            <div></div>
            <div></div>
            <div></div>
            <div class="caseChange1-1">了解案例</div>
          </div>
          <div class="caseChange1">
            <div></div>
            <div></div>
            <div></div>
            <div class="caseChange1-1">了解案例</div>
          </div>
          <div class="caseChange1">
            <div></div>
            <div></div>
            <div></div>
            <div class="caseChange1-1">了解案例</div>
          </div>
          <div class="caseChange1">
            <div></div>
            <div></div>
            <div></div>
            <div class="caseChange1-1">了解案例</div>
          </div> -->
        <!-- </div> -->
        <div></div>

        <!-- 品牌 -->
        <div class="">
          <div class="flex jc-space" style="margin: 40px 0 20px 0;">
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand1" style="width: 175px; height: 75px"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand2" style="width: 175px; height: 75px"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand3" style="width: 200px; height: 85px"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand4" style="width: 175px; height: 75px"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand5" style="width: 175px; height: 75px"></div>
            </div>
          </div>

          <div class="flex jc-space" style="margin: 30px 0;">
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand11" style="width: 150px; height: 130px;"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand12" style="width: 150px; height: 72px"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand13" style="width: 150px; height: 55px"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand14" style="width: 150px; height: 60px"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand15" style="width: 140px; height: 140px"></div>
            </div>
          </div>

          <div class="flex jc-space" style="margin: 20px 0 60px 0;">
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand6" style="width: 140px; height: 120px;"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand7" style="width: 140px; height: 140px"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand8" style="width: 120px; height: 120px"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand9" style="width: 150px; height: 150px"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand10" style="width: 120px; height: 120px"></div>
            </div>
          </div>
        </div>

        <div style="width: 100%;">
          <div class="flex ja" style="margin:100px 0 100px 0;font-size: 20px;">
            <div style="margin-right: 120px;color: #DADDE4;">---------------</div>
            <div style="color: #97989f;">更多案例，正在梳理中</div>
            <div style="margin-left: 120px;color: #DADDE4;">---------------</div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import HeadNav from "@/components/headNav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "",
  components: {
    HeadNav,
    Footer,
  },
  data() {
    return {
      caseList: [
        {
          id: 0,
          url: "/customerCaseDetails",
        },
        {
          id: 1,
          url: "",
        },
        {
          id: 2,
          url: "",
        },
        {
          id: 3,
          url: "",
        },
      ],
    };
  },
  mounted() {
    // this.timeChange();
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  methods: {
    changeCase(e) {
      this.$router.push(e);
    },
  },
};
</script>

<style src="@/style/CustomerCase.css" scoped></style>
<style lang="scss" scoped></style>
